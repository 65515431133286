jQuery(document).ready(function($) {

	createSticky($(".c-header"));

	$(window).on('scroll', function() {
		var st = $(this).scrollTop();
		$('#js__emotion').css({
			'transform': 'translateY('+ (st/2) +'px)'
		});
	});

	$("#js__c-header__navtoggle").click(function() {
		$(".c-navigation").fadeToggle();
		$(".c-navicon", this).toggleClass("c-navicon--active");
	});

	$('.c-navigation').onePageNav({
		currentClass: 'current',
		changeHash: false,
		scrollSpeed: 750,
		scrollThreshold: 0.25,
		filter: '',
		easing: 'swing',
		begin: function() {
			//I get fired when the animation is starting
		},
		end: function() {
			//I get fired when the animation is ending
		},
		scrollChange: function($currentListItem) {
			//I get fired when you enter a section and I pass the list item of the section
		}
	});

});
